<template>
    <div class="animated fadeIn">
        <b-card :title="$t('message.transaction')">
        <b-tabs @input="changeTabs" v-if="userBusiness || role_id === 'admin'">        
<!--            new tab             -->
            <b-tab :title="$t('message.washingMachineFarm')" v-if="userBusiness.indexOf('washing') >= 0 || role_id === 'admin'" @click="getData('washing')">
                <b-form @submit.prevent="searchFn">
                    <div class="row">
                        <div class="col-sm-6 col-md-4">
                            {{ $t("message.machineIdFarm") }} : <br>
                            <multi-list-select 
                                :list="machineData"
                                option-value="machineId"
                                option-text="name"
                                :selected-items="selectedMachine"
                                placeholder="All"
                                @select="onSelectMachine">
                            </multi-list-select>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.deviceId") }} : <br>
                            <b-form-input v-model="searchData.s_device"/>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.refNumber") }} : <br>
                            <b-form-input v-model="searchData.s_refNumber"/>
                        </div>
                        
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.date") }} : <br>
                            <date-picker type="datetime" v-model="searchData.s_datetime" lang="en" range format="yyyy-MM-dd"></date-picker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-4">
                            {{ $t("message.product") }} : <br>
                            <multi-list-select 
                                :list="ProductItems"
                                option-value="id"
                                option-text="name"
                                :selected-items="selectedProduct"
                                placeholder="All"
                                @select="onSelect">
                            </multi-list-select>    
                        </div> 
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.paymentType") }}: <br>
                            <b-form-select v-model="searchData.s_paymentType" :options="$t('message.arrays.payment')"/>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.status") }} : <br>
                            <b-form-select v-model="searchData.s_status" :options="$t('message.arrays.statusTrans')"/>
                        </div>     
                        <div class="col-sm-6 col-md-3">
                            <br><b-btn variant="primary" type="submit"><i class="fa fa-search"></i>&nbsp;{{ $t("message.search") }}</b-btn>&nbsp;&nbsp;&nbsp;&nbsp;
                            <b-btn v-on:click="ExportFn()" variant="success"><i class="icon-docs"></i>&nbsp;{{ $t("message.export") }}</b-btn>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-sm-6 col-md-2">
                            รายการทั้งหมด : {{sumQty}}
                        </div>
                        <div class="col-sm-6 col-md-2">
                            รายได้ทั้งหมด : {{sumProfit}}
                        </div>
                        <div class="col-sm-6 col-md-2">
                            เงินสดทั้งหมด : {{sumAcceptorMoney}}
                        </div>
                    </div>
<!--
                    <div class="row">
                        
                    </div>
-->
                    <br>
                </b-form>
                <div class="table-responsive">
                <table class="table2 table-hover">
                    <thead style="text-align: center">
                        <tr class="bg-primary">
                            <th>transaction ID</th>
                            <th>{{ $t("message.refNumber") }}</th>
                            <th>{{ $t("message.machineIdFarm") }}</th>
                            <th>{{ $t("message.deviceId") }}</th>
                            <th>{{ $t("message.product") }}</th>
<!--                            <th>จำนวน</th>-->
                            <th style="text-align: center">{{ $t("message.price") }}</th>
                            <th style="text-align: center">{{ $t("message.insert") }}</th>
                            <th style="text-align: center">{{ $t("message.change") }}</th>
                            <th style="text-align: center">{{ $t("message.creditBefore") }}</th>
                            <th style="text-align: center">{{ $t("message.creditAfter") }}</th>
                            <th>{{ $t("message.paymentType") }}</th>
                            <th>{{ $t("message.status") }}</th>
                            <th>{{ $t("message.date") }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, index, key) in rowData.rows"> 
                            <tr style="text-align: center">
                                <td :id="index + 'tran2'">
                                    <!-- ...{{item.transactionId.substr(15,20)}}
                                    <b-popovers 
                                        :target="index + 'tran2'.toString()"
                                        triggers="hover"
                                        placement="top">
                                        <strong>{{item.transactionId}}</strong>
                                    </b-popovers> -->
                                    {{item.transactionId}}
                                </td>
                                <td style="text-align: center">{{item.refNumber ? item.refNumber : '-'}}</td>
                                <td style="text-align: center">{{item.machineId}}</td>
                                <td style="text-align: center">{{item.deviceId}}</td>
                                <td style="text-align: center">
                                    <div v-if="item.paymentType === 'addCredit'">
                                        {{ $t("message.addCredit") }}
                                    </div>
                                    <template v-else-if="item.paymentType === 'deductCredit'">
                                        {{ $t("message.deductCredit") }}
                                    </template>
                                    <template v-else>
                                        <div :id="index + 'pd2'" v-if="item.product.name.length >= 14 ">    {{item.product.name.substr(0,14)}}...
                                        </div>
                                        <div v-else>{{item.product.name}}</div>
                                        <b-popovers 
                                            :target="index + 'pd2'.toString()"
                                             triggers="hover"
                                            placement="top">
                                            <strong>{{item.product.name}}</strong>
                                        </b-popovers>
                                    </template>
                                </td>
<!--          <td style="text-align: center">{{item.qty}}</td>-->
                                <td style="text-align: right">{{item.price}}</td>
                                <td style="text-align: right"><template v-if="item.acceptor"><span class="spanCustom" @click="showDetailsMoney(item)">{{item.acceptorMoney}}</span></template></td>
                                <td style="text-align: right"><template v-if="item.acceptor"><span class="spanCustom" @click="showDetailsMoney(item)">{{item.changerMoney}}</span></template></td>
                                <td style="text-align: right">{{item.creditCMBefore}}</td>
                                <td style="text-align: right">{{item.creditCMAfter}}</td>
                                <td style="text-align: center">{{item.paymentType}}</td>
<!--          <td style="text-align: center">{{item.type}}</td>-->
                                <template v-if="item.status === 'SUCCESS'">
                                    <td style="text-align: center"><span class="badge badge-success" style="font-size: 100%">{{ $t("message.success") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'PROCESS'">
                                    <td style="text-align: center"><span class="badge badge-warning" style="font-size: 100%">{{ $t("message.washing") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'CANCEL_BY_USER'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.cancelByUser") }}</span></td>
                                </template>
                                 <template v-else-if="item.status === 'CANCEL_BY_ADMIN'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.cancelByAdmin") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'CANCEL_BY_MC_FAIL'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.machineError") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'CANCEL'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.cancel") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'FAILED'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.fail") }}</span></td>
                                </template>
                                <td style="text-align: center">{{item.createdAt | moment("YYYY-MM-DD HH:mm")}}</td>
                                <template>
                                    <td style="text-align: center">
                                        <b-btn variant="warning" @click=showDetails(item) size="sm">details</b-btn>
                                    </td>
                                </template>
                            </tr>
                        </template>
                    </tbody>
                    <tfoot style="text-align: center">
                        <th></th>    
                        <th></th>    
                        <th></th>    
                        <th></th>    
                        <th>{{ $t("message.sum") }}</th>    
                        <!--        <th>{{rowData.totalQty}}</th>  -->
                        <th style="text-align: right">{{rowData.totalPrices}}</th>  
                        <th style="text-align: right">{{rowData.totalAcceptorMoney}}</th>  
                        <th style="text-align: right">{{rowData.totalChangerMoney}}</th> 
<!--
                        <th style="text-align-last: right">{{rowData.totalcreditCMBefore}}</th>   
                        <th style="text-align-last: right">{{rowData.totalcreditCMAfter}}</th>
-->
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tfoot>
                </table>
                </div><br>
                <b-pagination size="md" :total-rows=totalRows v-model="pagination" :per-page=itemperPage @input="pageChanged(pagination)"></b-pagination>
            </b-tab>
            

<!--            new tab             -->
            <b-tab :title="$t('message.oilPump')" v-if="userBusiness.indexOf('oilpump') >= 0 || role_id === 'admin'" @click="getData('oilpump')">
                <b-form @submit.prevent="searchFn">
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            {{ $t("message.machineId") }} : <br>
                            <multi-list-select 
                                :list="machineData"
                                option-value="machineId"
                                option-text="name"
                                :selected-items="selectedMachine"
                                placeholder="All"
                                @select="onSelectMachine">
                            </multi-list-select>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.refNumber") }} : <br>
                            <b-form-input v-model="searchData.s_refNumber"/>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.date") }} : <br>
                            <date-picker type="datetime" v-model="searchData.s_datetime" lang="en" range format="yyyy-MM-dd"></date-picker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-4">
                            {{ $t("message.product") }} : <br>
                            <multi-list-select 
                                :list="ProductItems"
                                option-value="id"
                                option-text="name"
                                :selected-items="selectedProduct"
                                placeholder="All"
                                @select="onSelect">
                            </multi-list-select>    
                        </div> 
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.paymentType") }}: <br>
                            <b-form-select v-model="searchData.s_paymentType" :options="$t('message.arrays.payment')"/>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.status") }} : <br>
                            <b-form-select v-model="searchData.s_status" :options="$t('message.arrays.statusTrans')"/>
                        </div>     
                        <div class="col-sm-6 col-md-3">
                            <br><b-btn variant="primary" type="submit"><i class="fa fa-search"></i>&nbsp;{{ $t("message.search") }}</b-btn>&nbsp;&nbsp;&nbsp;&nbsp;
                            <b-btn v-on:click="ExportFn()" variant="success"><i class="icon-docs"></i>&nbsp;{{ $t("message.export") }}</b-btn>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-sm-6 col-md-2">
                            รายการทั้งหมด : {{sumQty}}
                        </div>
                        <div class="col-sm-6 col-md-2">
                            รายได้ทั้งหมด : {{sumProfit}}
                        </div>
                        <div class="col-sm-6 col-md-2">
                            เงินสดทั้งหมด : {{sumAcceptorMoney}}
                        </div>
                    </div>
                    <br>
                </b-form>
                <div class="table-responsive">
                <table class="table2 table-hover">
                    <thead style="text-align: center">
                        <tr class="bg-primary">
                            <th>transaction ID</th>
                            <th>{{ $t("message.machineId") }}</th>
                            <th>{{ $t("message.slot") }}</th>
                            <th>{{ $t("message.product") }}</th>
<!--                            <th>จำนวน</th>-->
                            <th style="text-align: center">{{ $t("message.price") }}</th>
                            <th style="text-align: center">{{ $t("message.insert") }}</th>
                            <th style="text-align: center">{{ $t("message.change") }}</th>
                            <th>{{ $t("message.paymentType") }}</th>
                            <th>{{ $t("message.status") }}</th>
                            <th>{{ $t("message.date") }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, index, key) in rowData.rows"> 
                            <tr style="text-align: center">
                                <td :id="index + 'tran3'">
                                    <!-- ...{{item.transactionId.substr(15,20)}}
                                    <b-popovers 
                                        :target="index + 'tran3'.toString()"
                                        triggers="hover"
                                        placement="top">
                                        <strong>{{item.transactionId}}</strong>
                                    </b-popovers> -->
                                    {{item.transactionId}}
                                </td>
                                <td style="text-align: center">{{item.machineId}}</td>
                                <td style="text-align: center">{{item.slotId}}</td>
                                <td style="text-align: center">
                                    <template v-if="item.paymentType === 'addCredit'">
                                        <td>{{ $t("message.addCredit") }}</td>
                                    </template>
                                    <template v-else-if="item.paymentType === 'deductCredit'">
                                        {{ $t("message.deductCredit") }}
                                    </template>
                                    <template v-else>
                                        <div :id="index + 'pd3'" v-if="item.product.name.length >= 14 ">    {{item.product.name.substr(0,14)}}...
                                        </div>
                                        <div v-else>{{item.product.name}}</div>
                                        <b-popovers 
                                            :target="index + 'pd3'.toString()"
                                             triggers="hover"
                                            placement="top">
                                            <strong>{{item.product.name}}</strong>
                                        </b-popovers>
                                    </template>
                                </td>
<!--          <td style="text-align: center">{{item.qty}}</td>-->
                                <td style="text-align: right">{{item.price}}</td>
                                <td style="text-align: right"><template v-if="item.acceptor"><span class="spanCustom" @click="showDetailsMoney(item)">{{item.acceptorMoney}}</span></template></td>
                                <td style="text-align: right"><template v-if="item.acceptor"><span class="spanCustom" @click="showDetailsMoney(item)">{{item.changerMoney}}</span></template></td>
                                <td style="text-align: center">{{item.paymentType}}</td>
<!--          <td style="text-align: center">{{item.type}}</td>-->
                                <template v-if="item.status === 'SUCCESS'">
                                    <td style="text-align: center"><span class="badge badge-success" style="font-size: 100%">{{ $t("message.success") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'PROCESS'">
                                    <td style="text-align: center"><span class="badge badge-warning" style="font-size: 100%">{{ $t("message.washing") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'CANCEL_BY_USER'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.cancelByUser") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'CANCEL_BY_ADMIN'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.cancelByAdmin") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'CANCEL_BY_MC_FAIL'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.machineError") }}</span></td>
                                </template>
                                <template v-else><td style="text-align: center">
                                    <span class="badge badge-danger" style="font-size: 100%">{{ $t("message.fail") }}</span></td>
                                </template>
                                <td style="text-align: center">{{item.createdAt | moment("YYYY-MM-DD HH:mm")}}</td>
                                <template>
                                    <td style="text-align: center">
                                        <b-btn variant="warning" @click=showDetails(item) size="sm">details</b-btn>
                                    </td>
                                </template>
                            </tr>
                        </template>
                    </tbody>
                    <tfoot style="text-align: center">
                        <th></th>     
                        <th></th>    
                        <th></th>    
                        <th>{{ $t("message.sum") }}</th>    
                        <!--        <th>{{rowData.totalQty}}</th>  -->
                        <th style="text-align: right">{{rowData.totalPrices}}</th>  
                        <th style="text-align: right">{{rowData.totalAcceptorMoney}}</th>  
                        <th style="text-align: right">{{rowData.totalChangerMoney}}</th> 
                        <th></th>
                        <th></th>
                        <th></th>
                    </tfoot>
                </table>
                </div><br>
                <b-pagination size="md" :total-rows=totalRows v-model="pagination" :per-page=itemperPage @input="pageChanged(pagination)"></b-pagination>
            </b-tab>
            
            <!--            new tab             -->
            <b-tab :title="$t('message.vendingMachine')" v-if="userBusiness.indexOf('vending') >= 0 || role_id === 'admin'" @click="getData('vending')">
                <b-form @submit.prevent="searchFn">
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            {{ $t("message.machineId") }} : <br>
                            <multi-list-select 
                                :list="machineData"
                                option-value="machineId"
                                option-text="name"
                                :selected-items="selectedMachine"
                                placeholder="All"
                                @select="onSelectMachine">
                            </multi-list-select>
                        </div>
                        <!-- <div class="col-sm-6 col-md-2">
                            {{ $t("message.deviceId") }} : <br>
                            <b-form-input v-model="searchData.s_slotId"/>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.refNumber") }} : <br>
                            <b-form-input v-model="searchData.s_refNumber"/>
                        </div> -->
                        
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.date") }} : <br>
                            <date-picker type="datetime" v-model="searchData.s_datetime" lang="en" range format="yyyy-MM-dd"></date-picker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-4">
                            {{ $t("message.product") }} : <br>
                            <multi-list-select 
                                :list="ProductItems"
                                option-value="id"
                                option-text="name"
                                :selected-items="selectedProduct"
                                placeholder="All"
                                @select="onSelect">
                            </multi-list-select>    
                        </div> 
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.paymentType") }}: <br>
                            <b-form-select v-model="searchData.s_paymentType" :options="$t('message.arrays.payment')"/>
                        </div>
                        <!-- <div class="col-sm-6 col-md-2">
                            {{ $t("message.status") }} : <br>
                            <b-form-select v-model="searchData.s_status" :options="$t('message.arrays.statusTrans')"/>
                        </div>      -->
                        <div class="col-sm-6 col-md-3">
                            <br><b-btn variant="primary" type="submit"><i class="fa fa-search"></i>&nbsp;{{ $t("message.search") }}</b-btn>&nbsp;&nbsp;&nbsp;&nbsp;
                            <b-btn v-on:click="ExportFn()" variant="success"><i class="icon-docs"></i>&nbsp;{{ $t("message.export") }}</b-btn>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-sm-6 col-md-2">
                            รายการทั้งหมด : {{sumQty}}
                        </div>
                        <div class="col-sm-6 col-md-2">
                            รายได้ทั้งหมด : {{sumProfit}}
                        </div>
                        <div class="col-sm-6 col-md-2">
                            เงินสดทั้งหมด : {{sumAcceptorMoney}}
                        </div>
                    </div>
                    <br>
                </b-form>
                <div class="table-responsive">
                <table class="table2 table-hover">
                    <thead style="text-align: center">
                        <tr class="bg-primary">
                            <th>transaction ID</th>
                            <th>{{ $t("message.machineId") }}</th>
                            <th>{{ $t("message.product") }}</th>
                            <th>{{ $t("message.amount") }}</th>
                            <th style="text-align: center">{{ $t("message.price") }}</th>
                            <th style="text-align: center">{{ $t("message.insert") }}</th>
                            <th>{{ $t("message.status") }}</th>
                            <th>{{ $t("message.date") }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, index, key) in rowData.rows"> 
                            <tr style="text-align: center">
                                <td :id="index + 'tran4'">
                                    <!-- ...{{item.transactionId.substr(15,20)}}
                                    <b-popovers 
                                        :target="index + 'tran4'.toString()"
                                        triggers="hover"
                                        placement="top">
                                        <strong>{{item.transactionId}}</strong>
                                    </b-popovers> -->
                                    {{item.transactionId}}
                                </td>
                                <td style="text-align: center">{{item.machineId}}</td>
                                <td style="text-align: center">
                                    <template v-if="item.paymentType === 'addCredit'">
                                        <td>{{ $t("message.addCredit") }}</td>
                                    </template>
                                    <template v-else-if="item.paymentType === 'deductCredit'">
                                        {{ $t("message.deductCredit") }}
                                    </template>
                                    <template v-else>
                                        <div :id="index + 'pd4'" v-if="item.product.name.length >= 14 ">    {{item.product.name.substr(0,14)}}...
                                        </div>
                                        <div v-else>{{item.product.name}}</div>
                                        <b-popovers 
                                            :target="index + 'pd4'.toString()"
                                             triggers="hover"
                                            placement="top">
                                            <strong>{{item.product.name}}</strong>
                                        </b-popovers>
                                    </template>
                                </td>
                                <td style="text-align: center">{{item.qty}}</td>
                                <td style="text-align: right">{{item.price}}</td>
                                <td style="text-align: right"><template v-if="item.acceptor"><span class="spanCustom">{{item.acceptorMoney}}</span></template></td>
                                <template v-if="item.status === 'SUCCESS'">
                                    <td style="text-align: center"><span class="badge badge-success" style="font-size: 100%">{{ $t("message.success") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'CANCEL_BY_USER'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.cancelByUser") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'CANCEL_BY_ADMIN'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.cancelByAdmin") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'CANCEL_BY_MC_FAIL'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.machineError") }}</span></td>
                                </template>
                                <template v-else><td style="text-align: center">
                                    <span class="badge badge-danger" style="font-size: 100%">{{ $t("message.fail") }}</span></td>
                                </template>
                                <td style="text-align: center">{{item.createdAt | moment("YYYY-MM-DD HH:mm")}}</td>
                                <template>
                                    <td style="text-align: center">
                                        <b-btn variant="warning" @click=showDetails(item) size="sm">details</b-btn>
                                    </td>
                                </template>
                            </tr>
                        </template>
                    </tbody>
                    <tfoot style="text-align: center">
                        <th></th>     
                        <th></th>      
                        <th>{{ $t("message.sum") }}</th>    
                        <th style="text-align: center">{{rowData.totalQty}}</th>  
                        <th style="text-align: right">{{rowData.totalPrices}}</th>  
                        <th style="text-align: right">{{rowData.totalAcceptorMoney}}</th>      
                        <th></th>
                        <th></th>
                        <th></th>
                    </tfoot>
                </table>
                </div><br>
                <b-pagination size="md" :total-rows=totalRows v-model="pagination" :per-page=itemperPage @input="pageChanged(pagination)"></b-pagination>
            </b-tab>


            <b-tab :title="$t('message.waterDrinkingMachine')" v-if="userBusiness.indexOf('dw_vending') >= 0 || role_id === 'admin'" @click="getData('dw_vending')"> 
                <b-form @submit.prevent="searchFn">
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            {{ $t("message.machineId") }} : <br>
                            <multi-list-select 
                                :list="machineData"
                                option-value="machineId"
                                option-text="name"
                                :selected-items="selectedMachine"
                                placeholder="All"
                                @select="onSelectMachine">
                            </multi-list-select>   
                        </div> 
                        <!-- <div class="col-sm-6 col-md-2">
                            {{ $t("message.slot") }} : <br>
                            <b-form-input v-model="searchData.s_device"/>
                        </div> -->
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.date") }} : <br>
                            <date-picker type="datetime" v-model="searchData.s_datetime" lang="en" range format="yyyy-MM-dd"></date-picker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-4">
                            {{ $t("message.product") }} : <br>
                            <multi-list-select 
                                :list="ProductItems"
                                option-value="id"
                                option-text="name"
                                :selected-items="selectedProduct"
                                placeholder="All"
                                @select="onSelect">
                            </multi-list-select>    
                        </div> 
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.paymentType") }}: <br>
                            <b-form-select v-model="searchData.s_paymentType" :options="$t('message.arrays.payment')"/>
                        </div>
                        <div class="col-sm-6 col-md-3">
                            <br><b-btn variant="primary" type="submit"><i class="fa fa-search"></i>&nbsp;{{ $t("message.search") }}</b-btn>&nbsp;&nbsp;&nbsp;&nbsp;
                            <b-btn v-on:click="ExportFn()" variant="success"><i class="icon-docs"></i>&nbsp;{{ $t("message.export") }}</b-btn>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-sm-6 col-md-2">
                            รายการทั้งหมด : {{sumQty}}
                        </div>
                        <div class="col-sm-6 col-md-2">
                            รายได้ทั้งหมด : {{sumProfit}}
                        </div>
                        <div class="col-sm-6 col-md-2">
                            เงินสดทั้งหมด : {{sumAcceptorMoney}}
                        </div>
                    </div>
                    <br>
                </b-form>
                <div class="table-responsive">
                <table class="table2 table-hover">
                    <thead style="text-align: center">
                        <tr class="bg-primary">
                            <th>transaction ID</th>
                            <th>{{ $t("message.machineId") }}</th>
                            <th>{{ $t("message.product") }}</th>
<!--                            <th>จำนวน</th>-->
                            <th>{{ $t("message.amount") }}</th>
                            <th style="text-align: center">{{ $t("message.price") }}</th>
                            <th style="text-align: center">{{ $t("message.insert") }}</th>
                            <th>{{ $t("message.paymentType") }}</th>
                            <th>{{ $t("message.status") }}</th>
                            <th>{{ $t("message.date") }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, index, key) in rowData.rows"> 
                            <tr style="text-align: center">
                                <td :id="index + 'tran5'">
                                    <!-- ...{{item.transactionId.substr(15,20)}}
                                    <b-popovers 
                                        :target="index + 'tran5'.toString()"
                                        triggers="hover"
                                        placement="top">
                                        <strong>{{item.transactionId}}</strong>
                                    </b-popovers> -->
                                    {{item.transactionId}}
                                </td>
                                <td style="text-align: center">{{item.machineId}}</td>
                                <td style="text-align: center">
                                    <template v-if="item.paymentType === 'addCredit'">
                                        <td>{{ $t("message.addCredit") }}</td>
                                    </template>
                                    <template v-else-if="item.paymentType === 'deductCredit'">
                                        {{ $t("message.deductCredit") }}
                                    </template>
                                    <template v-else>
                                        <div :id="index + 'pd5'" v-if="item.product.name.length >= 14 ">    {{item.product.name.substr(0,14)}}...
                                        </div>
                                        <div v-else>{{item.product.name}}</div>
                                        <b-popovers 
                                            :target="index + 'pd5'.toString()"
                                             triggers="hover"
                                            placement="top">
                                            <strong>{{item.product.name}}</strong>
                                        </b-popovers>
                                    </template>
                                </td>
<!--          <td style="text-align: center">{{item.qty}}</td>-->
                                <td style="text-align: center">{{item.qty}}</td>
                                <td style="text-align: right">{{item.price}}</td>
                                <td style="text-align: right"><template v-if="item.acceptor"><span class="spanCustom" @click="showDetailsMoney(item)">{{item.acceptorMoney}}</span></template></td>
                                <td style="text-align: center">{{item.paymentType}}</td>
<!--          <td style="text-align: center">{{item.type}}</td>-->
                                <template v-if="item.status === 'SUCCESS'">
                                    <td style="text-align: center"><span class="badge badge-success" style="font-size: 100%">{{ $t("message.success") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'PROCESS'">
                                    <td style="text-align: center"><span class="badge badge-warning" style="font-size: 100%">{{ $t("message.washing") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'CANCEL_BY_USER'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.cancelByUser") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'CANCEL_BY_ADMIN'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.cancelByAdmin") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'CANCEL_BY_MC_FAIL'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.machineError") }}</span></td>
                                </template>
                                <template v-else><td style="text-align: center">
                                    <span class="badge badge-danger" style="font-size: 100%">{{ $t("message.fail") }}</span></td>
                                </template>
                                <td style="text-align: center">{{item.createdAt | moment("YYYY-MM-DD HH:mm")}}</td>
                                <template>
                                    <td style="text-align: center">
                                        <b-btn variant="warning" @click=showDetails(item) size="sm">details</b-btn>
                                    </td>
                                </template>
                            </tr>
                        </template>
                    </tbody>
                    <tfoot style="text-align: center">
                        <th></th>     
                        <th></th>    
                        <th></th>    
                        <th>{{ $t("message.sum") }}</th>    
                        <!--        <th>{{rowData.totalQty}}</th>  -->
                        <th style="text-align: right">{{rowData.totalPrices}}</th>  
                        <th style="text-align: right">{{rowData.totalAcceptorMoney}}</th>  
                        <th style="text-align: right">{{rowData.totalChangerMoney}}</th> 
                        <th></th>
                        <th></th>
                        <th></th>
                    </tfoot>
                </table>
                </div><br>
                <b-pagination size="md" :total-rows=totalRows v-model="pagination" :per-page=itemperPage @input="pageChanged(pagination)"></b-pagination>
            </b-tab>

            <b-tab :title="$t('message.addCredit')" @click="getData('addCredit')">
                <b-form @submit.prevent="searchFn">
                    <div class="row">
                        <div class="col-sm-6 col-md-4">
                            {{ $t("message.machineId") }} : <br>
                            <multi-list-select 
                                :list="machineData"
                                option-value="machineId"
                                option-text="name"
                                :selected-items="selectedMachine"
                                placeholder="All"
                                @select="onSelectMachine">
                            </multi-list-select>   
                        </div> 
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.refNumber") }} : <br>
                            <b-form-input v-model="searchData.s_refNumber"/>
                        </div>
                        
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.date") }} : <br>
                            <date-picker type="datetime" v-model="searchData.s_datetime" lang="en" range format="yyyy-MM-dd"></date-picker>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.paymentType") }}: <br>
                            <b-form-select v-model="searchData.s_paymentType" :options="$t('message.arrays.payment')"/>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            {{ $t("message.status") }} : <br>
                            <b-form-select v-model="searchData.s_status" :options="$t('message.arrays.statusTrans')"/>
                        </div> 
                        <!-- <div class="col-sm-6 col-md-6">
                            {{ $t("message.product") }} : <br>
                            <multi-list-select 
                                :list="ProductItems"
                                option-value="id"
                                option-text="name"
                                :selected-items="selectedProduct"
                                placeholder="All"
                                @select="onSelect">
                            </multi-list-select>    
                        </div>  -->
                        <div class="col-sm-6 col-md-3">
                            <br><b-btn variant="primary" type="submit"><i class="fa fa-search"></i>&nbsp;{{ $t("message.search") }}</b-btn>&nbsp;&nbsp;&nbsp;&nbsp;
                            <b-btn v-on:click="ExportFn()" variant="success"><i class="icon-docs"></i>&nbsp;{{ $t("message.export") }}</b-btn>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-sm-6 col-md-2">
                            รายการทั้งหมด : {{sumQty}}
                        </div>
                        <div class="col-sm-6 col-md-2">
                            รายได้ทั้งหมด : {{sumProfit}}
                        </div>
                        <div class="col-sm-6 col-md-2">
                            เงินสดทั้งหมด : {{sumAcceptorMoney}}
                        </div>
                    </div>
                    <br>
                </b-form>
                <div class="table-responsive">
                <table class="table2 table-hover">
                    <thead style="text-align: center">
                        <tr class="bg-primary">
                            <th>transaction ID</th>
                            <th>{{ $t("message.machineId") }}</th>
                            <th>{{ $t("message.refNumber") }}</th>
                            <!-- <th>{{ $t("message.product") }}</th> -->
<!--                            <th>จำนวน</th>-->
                            <!-- <th>{{ $t("message.amount") }}</th> -->
                            <th style="text-align: center">{{ $t("message.price") }}</th>
                            <th style="text-align: center">{{ $t("message.insert") }}</th>
                            <th>{{ $t("message.paymentType") }}</th>
                            <th>{{ $t("message.status") }}</th>
                            <th>{{ $t("message.date") }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, index, key) in rowData.rows"> 
                            <tr style="text-align: center" v-if="item.paymentType !== 'REFUND'">
                                <td :id="index + 'tran5'">
                                    {{item.transactionId}}
                                </td>
                                <td style="text-align: center">{{item.machineId}}</td>
                                <td style="text-align: center">{{item.refNumber}}</td>
                                <td style="text-align: right">{{item.price}}</td>
                                <td style="text-align: right"><template v-if="item.acceptor"><span class="spanCustom" @click="showDetailsMoney(item)">{{item.acceptorMoney}}</span></template></td>
                                <td style="text-align: center">{{item.paymentType}}</td>
                                <template v-if="item.status === 'SUCCESS'">
                                    <td style="text-align: center"><span class="badge badge-success" style="font-size: 100%">{{ $t("message.success") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'PROCESS'">
                                    <td style="text-align: center"><span class="badge badge-warning" style="font-size: 100%">{{ $t("message.washing") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'CANCEL_BY_USER'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.cancelByUser") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'CANCEL_BY_ADMIN'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.cancelByAdmin") }}</span></td>
                                </template>
                                <template v-else-if="item.status === 'CANCEL_BY_MC_FAIL'">
                                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.machineError") }}</span></td>
                                </template>
                                <template v-else><td style="text-align: center">
                                    <span class="badge badge-danger" style="font-size: 100%">{{ $t("message.fail") }}</span></td>
                                </template>
                                <td style="text-align: center">{{item.createdAt | moment("YYYY-MM-DD HH:mm")}}</td>
                                <template>
                                    <td style="text-align: center">
                                        <b-btn variant="warning" @click=showDetails(item) size="sm">details</b-btn>
                                    </td>
                                </template>
                            </tr>
                        </template>
                    </tbody>
                    <tfoot style="text-align: center">
                        <th></th>     
                        <th></th>       
                        <th>{{ $t("message.sum") }}</th>    
                        <!--        <th>{{rowData.totalQty}}</th>  -->
                        <th style="text-align: right">{{rowData.totalPrices}}</th>  
                        <th style="text-align: right">{{rowData.totalAcceptorMoney}}</th>  
                        <th style="text-align: right">{{rowData.totalChangerMoney}}</th> 
                        <th></th>
                        <th></th>
                        <th></th>
                    </tfoot>
                </table>
                </div><br>
                <b-pagination size="md" :total-rows=totalRows v-model="pagination" :per-page=itemperPage @input="pageChanged(pagination)"></b-pagination>
            </b-tab>
        </b-tabs> 
    </b-card>

    
    <b-modal ref="myModalRef" hide-footer hide-header>
      <div class="d-block text-left">
        <div class="col-12">        
          <table class="table2">
              <tr>
                <td colspan="3"><span style="font-weight:bold">{{ $t("message.insert") }}</span></td>
                <td>{{modalData.acceptorMoney}}</td>
              </tr>
              <tr>
                <td colspan="2"><span style="font-weight:bold">{{ $t("message.banknote") }}</span></td>
                <td><span style="font-weight:bold">20</span></td>
                <td><span>{{modalData.acceptor.bill['20']}}</span></td>
                <td></td><td><span style="font-weight:bold">{{ $t("message.coin") }}</span></td>
                <td><span style="font-weight:bold">1</span></td>
                <td><span>{{modalData.acceptor.coin['1']}}</span></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td><span style="font-weight:bold">50</span></td>
                <td><span>{{modalData.acceptor.bill['50']}}</span></td>
                <td></td>
                <td></td><td><span style="font-weight:bold">2</span></td>
                <td><span>{{modalData.acceptor.coin['2']}}</span></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td><span style="font-weight:bold">100</span></td>
                <td><span>{{modalData.acceptor.bill['100']}}</span></td>
                <td></td>
                <td></td><td><span style="font-weight:bold">5</span></td>
                <td><span>{{modalData.acceptor.coin['5']}}</span></td>
              </tr>
              <tr>
                <td colspan="5"></td>
                <td></td><td><span style="font-weight:bold">10</span></td>
                <td><span>{{modalData.acceptor.coin['10']}}</span></td>
              </tr>
              <tr>
                <td colspan="3"><span style="font-weight:bold">{{ $t("message.change") }}</span></td>
                <td>{{modalData.changerMoney}}</td>
              </tr>
              <tr>
                <td colspan="2"><span style="font-weight:bold">{{ $t("message.banknote") }}</span></td>
                <td><span style="font-weight:bold">20</span></td>
                <td><span>{{modalData.changer.bill['20']}}</span></td>
                <td></td><td><span style="font-weight:bold">{{ $t("message.coin") }}</span></td>
                <td><span style="font-weight:bold">1</span></td>
                <td><span>{{modalData.changer.coin['1']}}</span></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td><span style="font-weight:bold">50</span></td>
                <td><span>{{modalData.changer.bill['50']}}</span></td>
                <td></td>
                <td></td><td><span style="font-weight:bold">2</span></td>
                <td><span>{{modalData.changer.coin['2']}}</span></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td><span style="font-weight:bold">100</span></td>
                <td><span>{{modalData.changer.bill['100']}}</span></td>
                <td></td>
                <td></td><td><span style="font-weight:bold">5</span></td>
                <td><span>{{modalData.changer.coin['5']}}</span></td>
              </tr>
              <tr>
                <td colspan="5"></td>
                <td></td><td><span style="font-weight:bold">10</span></td>
                <td><span>{{modalData.changer.coin['10']}}</span></td>
              </tr>
            </tbody>
          </table>
        </div><!--/.col--> 
      </div>
    </b-modal>
    
    
    <b-modal ref="detailsModal" hide-footer hide-header @hidden="closeDetailsModal()" size="md">
      <b-list-group flush>
        <b-list-group-item><template><table class="table2">
        <tr>
          <td colspan="3"><span style="font-weight:bold">{{ $t("message.insert") }}</span></td>
          <td>{{detailsData.acceptorMoney}}</td>
        </tr>
        <tr>
          <td colspan="2"><span style="font-weight:bold">{{ $t("message.banknote") }}</span></td>
          <td><span style="font-weight:bold">20</span></td>
          <td><span>{{detailsData.acceptor.bill['20']}}</span></td>
          <td></td><td><span style="font-weight:bold">{{ $t("message.coin") }}</span></td>
          <td><span style="font-weight:bold">1</span></td>
          <td><span>{{detailsData.acceptor.coin['1']}}</span></td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td><span style="font-weight:bold">50</span></td>
          <td><span>{{detailsData.acceptor.bill['50']}}</span></td>
          <td></td>
          <td></td><td><span style="font-weight:bold">2</span></td>
          <td><span>{{detailsData.acceptor.coin['2']}}</span></td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td><span style="font-weight:bold">100</span></td>
          <td><span>{{detailsData.acceptor.bill['100']}}</span></td>
          <td></td>
          <td></td><td><span style="font-weight:bold">5</span></td>
          <td><span>{{detailsData.acceptor.coin['5']}}</span></td>
        </tr>
        <tr>
          <td colspan="5"></td>
          <td></td><td><span style="font-weight:bold">10</span></td>
          <td><span>{{detailsData.acceptor.coin['10']}}</span></td>
        </tr>
        <!-- <tr>
          <td colspan="3"><span style="font-weight:bold">{{ $t("message.change") }}</span></td>
          <td>{{detailsData.changerMoney}}</td>
        </tr>
        <tr>
          <td colspan="2"><span style="font-weight:bold">{{ $t("message.banknote") }}</span></td>
          <td><span style="font-weight:bold">20</span></td>
          <td><span>{{detailsData.changer.bill['20']}}</span></td>
          <td></td><td><span style="font-weight:bold">{{ $t("message.coin") }}</span></td>
          <td><span style="font-weight:bold">1</span></td>
          <td><span>{{detailsData.changer.coin['1']}}</span></td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td><span style="font-weight:bold">50</span></td>
          <td><span>{{detailsData.changer.bill['50']}}</span></td>
          <td></td>
          <td></td><td><span style="font-weight:bold">2</span></td>
          <td><span>{{detailsData.changer.coin['2']}}</span></td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td><span style="font-weight:bold">100</span></td>
          <td><span>{{detailsData.changer.bill['100']}}</span></td>
          <td></td>
          <td></td><td><span style="font-weight:bold">5</span></td>
          <td><span>{{detailsData.changer.coin['5']}}</span></td>
        </tr>
        <tr>
          <td colspan="5"></td>
          <td></td><td><span style="font-weight:bold">10</span></td>
          <td><span>{{detailsData.changer.coin['10']}}</span></td>
        </tr>   -->
        </table></template></b-list-group-item>
      <template v-if="detailsData.type === 'washing'">
        <b-list-group-item>
        <table class="table2">
          <tr>
            <td><span style="font-weight:bold">{{ $t("message.transactionId") }}</span></td>
            <td>{{detailsData.transactionId}}</td>
          </tr>
          <tr>
            <td><span style="font-weight:bold">{{ $t("message.refNumber") }}</span></td>
            <td>{{detailsData.refNumber}}</td>
          </tr>
          <tr>
            <td><span style="font-weight:bold">{{ $t("message.machineIdFarm") }}</span></td>
            <td>{{detailsData.machineId}}</td>
          </tr>
          <tr>
            <td><span style="font-weight:bold">{{ $t("message.deviceId") }}</span></td>
            <td>{{detailsData.deviceId}}</td>
          </tr>
          <tr>
            <td><span style="font-weight:bold">{{ $t("message.product") }}</span></td>
            <td>{{detailsData.product.name}}</td>
          </tr>
          <tr>
            <td><span style="font-weight:bold">{{ $t("message.insert") }}</span></td>
            <td>{{detailsData.acceptorMoney}}</td>
          </tr>
          <tr>
            <td><span style="font-weight:bold">{{ $t("message.selectedPrice") }}</span></td>
            <td v-if="detailsData.status === 'SUCCESS'">{{detailsData.price}}</td>
            <td v-else>{{detailsData.details ? detailsData.details.selectedPrices : detailsData.price}}</td>
          </tr>
          <tr>
            <td><span style="font-weight:bold">{{ $t("message.creditBefore") }}</span></td>
            <td>{{detailsData.creditCMBefore}}</td>
          </tr>
          <tr>
            <td><span style="font-weight:bold">{{ $t("message.creditAfter") }}</span></td>
            <td>{{detailsData.creditCMAfter}}</td>
          </tr>
          <tr>
            <td><span style="font-weight:bold">{{ $t("message.paymentType") }}</span></td>
            <td>{{detailsData.paymentType}}</td>
          </tr>
          <tr v-if="detailsData.paymentType === 'PROMPT PAY' && isAdmin(role_id)">
            <td><span style="font-weight:bold">{{ $t("message.transactionIdLGN") }}</span></td>
            <td>{{detailsData.paymentDetails.transId}}</td> 
          </tr>
          <tr>
            <td><span style="font-weight:bold">{{ $t("message.status") }}</span></td>
            <td>
                <template v-if="detailsData.status === 'SUCCESS'">
                    <td style="text-align: center"><span class="badge badge-success" style="font-size: 100%">{{ $t("message.success") }}</span></td>
                </template>
                <template v-else-if="detailsData.status === 'PROCESS'">
                    <td style="text-align: center"><span class="badge badge-warning" style="font-size: 100%">{{ $t("message.washing") }}</span></td>
                </template>
                <template v-else-if="detailsData.status === 'CANCEL_BY_USER'">
                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.cancelByUser") }}</span></td>
                </template>
                <template v-else-if="detailsData.status === 'CANCEL_BY_ADMIN'">
                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.cancelByAdmin") }}</span></td>
                </template>
                <template v-else-if="detailsData.status === 'CANCEL_BY_MC_FAIL'">
                    <td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t("message.machineError") }}</span></td>
                </template>
                <template v-else><td style="text-align: center">
                    <span class="badge badge-danger" style="font-size: 100%">{{ $t("message.fail") }}</span></td>
                </template>
            </td>
          </tr>
          <tr v-if="detailsData.paymentDetails.coupon">
            <td><span style="font-weight:bold">{{ $t("message.proName") }}</span></td>
            <td>{{detailsData.paymentDetails.promotionName}}</td> 
          </tr>
          <tr>
          <tr>
            <td><span style="font-weight:bold">{{ $t("message.date") }}</span></td>
            <td>{{detailsData.createdAt | moment("YYYY-MM-DD HH:mm")}}</td>
          </tr>
          
          
          
          <tr>
            <td><span style="font-weight:bold">{{ $t("message.sms") }}</span></td>
            <td>{{sms.status ? (sms.status === 1 ? $t("message.success") : $t("message.fail")) : '-'}}</td>
          </tr>
          <tr>
            <td><span style="font-weight:bold">{{ $t("message.smsStatus") }}</span></td>
            <td>{{sms.smsStatus ? sms.smsStatus : '-'}}</td>
          </tr>
          <tr>
            <td><span style="font-weight:bold">{{ $t("message.smsTime") }}</span></td>
            <td>{{sms.smsStatus ? sms.updatedAt : '-'}}</td>
          </tr>
        </table>
        </b-list-group-item>
      </template>
      <template v-else-if="detailsData.type === 'dw_vending'">
        <vue-json-pretty
          :path="detailsData.details"
          :data="detailsData.details"
          @click="handleClick">
        </vue-json-pretty>

      </template>
      </b-list-group>
    </b-modal>
    
    <b-modal ref="exportModal" :title="$t('message.summarize')" @hidden="closeExportModal()">
        <b-tabs>
            <b-tab :title="$t('message.sum')">
                <table class="table-modal">
                    <tr>
                        <td>{{ $t("message.date") }}</td>
                        <td>{{fromdate}} - {{todate}}</td>
                     </tr>
                     <tr>
                        <td>{{ $t("message.amount") }}</td>
                        <td>{{totalRows}}</td>
                    </tr>
                    <tr>
                        <td>{{ $t("message.sale") }}</td>
                        <td>{{totalPrices}}</td>
                    </tr>
                    <tr>
                        <td>{{ $t("message.income") }}</td>
                        <td>{{totalAcceptorMoney}}</td>
                    </tr>
                    <tr>
                        <td>{{ $t("message.change") }}</td>
                        <td>{{totalChangerMoney}}</td>
                    </tr>
                </table>
            </b-tab> 
            <b-tab :title="$t('message.moneyInbox')">
                <table class="table-modal" style="text-align: center">
                    <thead style="text-align-last: center">
                        <th>{{ $t('message.type') }}</th>
                        <th>{{ $t('message.amount') }}</th>
                        <th>{{ $t('message.balance') }}</th>
                    </thead>
                    <tbody>
                        <tr><th>{{ $t('message.coin') }}</th><td></td><td></td></tr>
                        <template v-for="(value, key) in sum.acceptor.coin">
                            <tr>
                                <td>{{key}}</td>
                                <td>{{value}}</td>
                                <td>{{value*key}}</td>
                            </tr>
                        </template>
                        <tr><th>{{ $t('message.banknote') }}</th><td></td><td></td></tr>
                        <template v-for="(value, key) in sum.acceptor.bill">
                            <tr>
                                <td>{{key}}</td>
                                <td>{{value}}</td>
                                <td>{{value*key}}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </b-tab>
            <b-tab :title="$t('message.changeInbox')">
                <table class="table-modal" style="text-align: center">
                    <thead style="text-align-last: center">
                        <th>{{ $t('message.type') }}</th>
                        <th>{{ $t('message.amount') }}</th>
                        <th>{{ $t('message.balance') }}</th>
                    </thead>
                    <tbody>
                        <tr><th>{{ $t('message.coin') }}</th><td></td><td></td></tr>
                        <template v-for="(value, key) in sum.changer.coin">
                            <tr>
                                <td>{{key}}</td>
                                <td>{{value}}</td>
                                <td>{{value*key}}</td>
                            </tr>
                        </template>
                        <tr><th>{{ $t('message.banknote') }}</th><td></td><td></td></tr>
                        <template v-for="(value, key) in sum.changer.bill">
                            <tr>
                                <td>{{key}}</td>
                                <td>{{value}}</td>
                                <td>{{value*key}}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </b-tab>
        </b-tabs>
        <div slot="modal-footer" class="w-100">
            <!-- <download-excel
                class   = "btn btn-warning float-right"
                :title = "title"
                :data   = "exportDataOld"
                :fields = "exportFieldOld"
                :name    = "reportFilename">
                Export(เก่า)
            <i class="icon-docs"></i>
            </download-excel> -->
            <download-excel
                class   = "btn btn-success float-right"
                :title = "title"
                :data   = "exportData"
                :fields = "exportField"
                :name    = "reportFilename">
                Export
            <i class="icon-docs"></i>
            </download-excel>
        </div>
    </b-modal>

  </div>

</template>

<script>
import webServices from "../script";
import DatePicker from "../custom_modules/vue2-datepicker";
import { MultiSelect, MultiListSelect } from "../custom_modules/search-select";
import moment from "moment";
import VueJsonPretty from "vue-json-pretty";
import _ from "lodash";
// import cTable from '../custom_modules/Table.vue'

function sumMoney(trans) {
              let keyCoin = Object.keys(trans[0].acceptor.coin);
              let keyBill = Object.keys(trans[0].acceptor.bill);
              let objAccCoin = {};
              let objAccBill = {};
              let objChaCoin = {};
              let objChaBill = {};
              const reducer = (accumulator, currentValue) =>
                accumulator + currentValue;
              for (var i = 0; i < keyCoin.length; i++) {
                objAccCoin[keyCoin[i]] = trans
                  .map((data) => data.acceptor.coin[keyCoin[i]])
                  .reduce(reducer);
                objChaCoin[keyCoin[i]] = trans
                  .map((data) => data.changer.coin[keyCoin[i]])
                  .reduce(reducer);
              }

              for (var i = 0; i < keyBill.length; i++) {
                objAccBill[keyBill[i]] = trans
                  .map((data) => data.acceptor.bill[keyBill[i]])
                  .reduce(reducer);
                objChaBill[keyBill[i]] = trans
                  .map((data) => data.changer.bill[keyBill[i]])
                  .reduce(reducer);
              }
              const sumMoney = {
                acceptor: {
                  coin: objAccCoin,
                  bill: objAccBill,
                },
                changer: {
                  coin: objChaCoin,
                  bill: objChaBill,
                },
              };
              console.log(sumMoney);
              return sumMoney;
            }
            
            function slice(object, keys) {
              return Object.keys(object)
                .filter(function (key) {
                  return keys.indexOf(key) >= 0;
                })
                .reduce(function (acc, key) {
                  acc[key] = object[key];
                  return acc;
                }, {});
            }

export default {
  name: "orders",
  components: {
    DatePicker,
    MultiSelect,
    MultiListSelect,
    VueJsonPretty,
  },
  data() {
    return {
      role_id: window.localStorage.getItem("roleID"),
      userBusiness: window.localStorage.getItem("business"),
      rowData: [],
      totalRows: 1,
      pagination: 1,
      itemperPage: 30,
      searchData: {
        s_datetime: [
          moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
          moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        ],
        s_id: "",
        s_machineid: "",
        s_device: "",
        s_product: "",
        s_product2: "",
        s_qty: "",
        s_price: "",
        s_acceptorMoney: "",
        s_changerMoney: "",
        s_status: "",
        s_paymentType: "",
        s_refNumber: "",
        s_type: "washing",
      },
      fromdate: "",
      todate: "",
      modalData: {
        acceptor: { bill: "", coin: "" },
        changer: { bill: "", coin: "" },
        product: { name: "" },
        details: {},
        device: {
          deviceDetails: {},
        },
      },
      detailsData: {
        details: {},
        changer: {
          coin: {},
          bill: {},
        },
        acceptor: {
          coin: {},
          bill: {},
        },
      },
      options: [
        { value: "", text: "ทั้งหมด" },
        { value: "SUCCESS", text: "สำเร็จ" },
        { value: "WASHING", text: "กำลังทำงาน" },
        { value: "CANCEL_BY_USER", text: "กดยกเลิก" },
        { value: "CANCEL_BY_MC_FAIL", text: "เครื่องขัดข้อง" },
        { value: "CANCEL_BY_ADMIN", text: "ยกเลิกโดยแอดมิน" },
      ],
      type: [
        { value: "", text: "ทั้งหมด" },
        { value: "washing", text: "ฟาร์มซักผ้า" },
        { value: "vending", text: "ตู้น้ำ" },
        { value: "oilpump", text: "ตู้น้ำมัน" },
      ],
      payment: [
        { value: "", text: "ทั้งหมด" },
        { value: "CASH", text: "เงินสด" },
        { value: "PROMPT PAY", text: "พร้อมเพย์" },
        { value: "LINE PAY", text: "ไลน์เพย์" },
        { value: "RABBIT", text: "แรบบิท" },
      ],
      ProductItems: [],
      selectedProduct: [],
      sms: {},
      exportData: [],
      exportField: {},
      exportDataOld: [],
      exportFieldOld: {},
      title: [],
      reportFilename: "",
      totalQty: 0,
      totalPrices: 0,
      totalAcceptorMoney: 0,
      totalChangerMoney: 0,
      sumQty: 0,
      sumProfit: 0,
      sumAcceptorMoney: 0,
      machineData: [],
      selectedMachine: [],
      sum: {
        changer: {
          coin: {},
          bill: {},
        },
        acceptor: {
          coin: {},
          bill: {},
        },
      },
    };
  },
  mounted() {
    this.getTrans(
      1,
      this.itemperPage,
      this.searchData.s_id,
      this.searchData.s_machineid,
      this.searchData.s_device,
      this.searchData.s_product,
      this.searchData.s_qty,
      this.searchData.s_price,
      this.searchData.s_acceptorMoney,
      this.searchData.s_status,
      "",
      "",
      this.searchData.s_changerMoney,
      this.searchData.s_paymentType,
      this.searchData.s_refNumber,
      this.searchData.s_type
    );
    this.getTransactionSum();
    this.getProduct();
    this.getMachine();
  },
  methods: {
    changeTabs(tabIndex) {
      console.log("tabIndex = " + tabIndex)
        // switch (tabIndex) {
        //   // case 0:
        //   //   this.searchData.s_type = ''
        //   //   this.searchFn()
        //   //   break
        //   case 0:
            // this.searchData.s_type = 'washing'
        //     // this.searchFn()
        //     break
        //   case 1:
        //     this.searchData.s_type = 'oilpump'
        //     // this.searchFn()
        //     break
        //   case 2:
        //     this.searchData.s_type = 'vending'
        //     // this.searchFn()
        //     break
        //   case 3:
        //     this.searchData.s_type = 'dw_vending'
        //     // this.searchFn()
        //     break
        //   case 4:
        //     this.searchData.s_type = 'addCredit'
        //     // this.searchFn()
        //     break
        // }
    },
    getMachine() {
      this.$Progress.start();
      webServices
        .getMachineList(window.localStorage.getItem("userId"))
        .then((res) => {
          this.$Progress.finish();
          this.machineData = res.data;
        })
        .catch((err) => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText,
            },
          });
          console.log("error @machine");
          console.log(err);
        });
    },
    onSelectMachine(items, lastSelectItem) {
      // console.log(items)
      this.selectedMachine = items;
      this.searchData.s_machineid = this.selectedMachine.map(
        (data) => data.machineId
      );
    },
    isAdmin(role) {
      return webServices.isAdmin(role);
    },
    pageChanged(PageNum) {
      // window.scrollTo(0, 0)
      this.getTrans(
        PageNum,
        this.itemperPage,
        this.searchData.s_id,
        this.searchData.s_machineid,
        this.searchData.s_device,
        this.searchData.s_product,
        this.searchData.s_qty,
        this.searchData.s_price,
        this.searchData.s_acceptorMoney,
        this.searchData.s_status,
        this.fromdate,
        this.todate,
        this.searchData.s_changerMoney,
        this.searchData.s_paymentType,
        this.searchData.s_refNumber,
        this.searchData.s_type
      );
    },
    getTrans(
      page,
      row,
      id,
      machineid,
      slot,
      product,
      qty,
      price,
      inputmoney,
      status,
      from,
      to,
      userId,
      acceptorMoney,
      changerMoney,
      paymentType,
      refNumber,
      type
    ) {
      this.$Progress.start();
      webServices
        .getTransaction(
          page,
          row,
          id,
          machineid,
          slot,
          product,
          qty,
          price,
          inputmoney,
          status,
          from,
          to,
          userId,
          acceptorMoney,
          changerMoney,
          paymentType,
          refNumber,
          type
        )
        .then((res) => {
          this.$Progress.finish();
          this.rowData = res.data;
          this.totalRows = res.data.count;
          this.rowData.totalPrices = this.rowData.totalPrices.toFixed(2);
          this.rowData.totalQty = this.rowData.totalQty.toFixed(2);
          this.rowData.totalAcceptorMoney = this.rowData.totalAcceptorMoney.toFixed(
            2
          );
          this.rowData.totalChangerMoney = this.rowData.totalChangerMoney.toFixed(
            2
          );
        })
        .catch((err) => {
          this.$Progress.fail();
          console.log("error Orders");
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: "เกิดข้อผิดพลาด",
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },
    searchFn() {
      if (this.searchData.s_datetime !== "") {
        var timeEnd = moment(this.searchData.s_datetime[1]).format("HH:mm:ss");
        if (timeEnd === "00:00:00" || timeEnd === "12:00:00 AM") {
          this.searchData.s_datetime[1].setHours(23, 59, 59, 999);
        }
        this.fromdate = moment(this.searchData.s_datetime[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.todate = moment(this.searchData.s_datetime[1]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        // console.log(this.todate)
      } else {
        this.fromdate = "";
        this.todate = "";
      }
      this.getTrans(
        1,
        this.itemperPage,
        this.searchData.s_id,
        this.searchData.s_machineid,
        this.searchData.s_device,
        this.searchData.s_product,
        this.searchData.s_qty,
        this.searchData.s_price,
        this.searchData.s_acceptorMoney,
        this.searchData.s_status,
        this.fromdate,
        this.todate,
        this.searchData.s_changerMoney,
        this.searchData.s_paymentType,
        this.searchData.s_refNumber,
        this.searchData.s_type
      );
      this.getTransactionSum();
      this.getProduct();
      // this.pagination = 1
    },
    showDetailsMoney(data) {
      // console.log(data)
      this.modalData = data;
      this.$refs.myModalRef.show();
    },
    async showDetails(data) {
      console.log(data);
      const smsParams = {
        transactionId: data.transactionId,
        machineId: data.machineId,
      };
      this.detailsData = data;
      if (data.type === "washing") {
        this.$Progress.start();
        await webServices
          .smsStatus(smsParams)
          .then((res) => {
            this.$Progress.finish();
            if (res.data) {
              this.sms = res.data;
              console.log(res.data);
              this.sms.updatedAt = moment(this.sms.updatedAt).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }
          })
          .catch((err) => {
            this.$Progress.fail();
            console.log("error @get SMS status");
            console.log(err);
            this.$toast.error({
              title: "ERROR",
              message: "เกิดข้อผิดพลาด",
            });
            if (err.response.status === 401) {
              webServices.tokenExpire();
            }
          });
      }
      this.$refs.detailsModal.show();
    },
    onSelect(items, lastSelectItem) {
      // console.log(items)
      this.selectedProduct = items;
      this.searchData.s_product = this.selectedProduct.map((data) => data.id);
    },
    getProduct() {
      webServices
        .getProductList({ type: this.searchData.s_type })
        .then((res) => {
          // console.log(res.data)
          this.ProductItems = res.data;
          // console.log(this.searchData.s_type)
        })
        .catch((err) => {
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText,
          };
          this.$toast.error({
            title: "ERROR",
            message: JSON.stringify(errorText),
          });
          console.log("error @get product ORDER");
          console.log(err);
        });
    },
    closeDetailsModal() {
      this.sms = {};
    },
    ExportFn(type) {
      this.searchFn();
      this.getTransactionSum();
      this.$Progress.start();
      webServices
        .getTransactionsReport(
          this.searchData.s_id,
          this.searchData.s_machineid,
          this.searchData.s_device,
          this.searchData.s_product,
          this.searchData.s_qty,
          this.searchData.s_price,
          this.searchData.s_acceptorMoney,
          this.searchData.s_status,
          this.fromdate,
          this.todate,
          this.searchData.s_changerMoney,
          this.searchData.s_paymentType,
          this.searchData.s_refNumber,
          this.searchData.s_type
        )
        .then((res) => {
          this.$Progress.finish();
          var data = res.data;
          this.exportData = data.rows.slice();
          this.exportDataOld = data.rows.slice();
          this.totalQty = webServices.addCommas(res.data.totalQty);
          this.totalPrices = webServices.addCommas(res.data.totalPrices);
          this.totalAcceptorMoney = webServices.addCommas(
            res.data.totalAcceptorMoney
          );
          this.totalChangerMoney = webServices.addCommas(
            res.data.totalChangerMoney
          );
          if (res.data.rows.length > 0) {

            let obj = sumMoney(this.exportData);
            let coinAcc = obj.acceptor.coin;
            let billAcc = obj.acceptor.bill;
            let coinCha = obj.changer.coin;
            let billCha = obj.changer.bill;
            this.sum.acceptor = {
              coin: coinAcc,
              bill: billAcc,
            };
            this.sum.changer = {
              coin: coinCha,
              bill: billCha,
            };
            this.title[0] = `วันที่ ${this.fromdate} ถึง ${this.todate}`;

            if (
              this.searchData.s_type === "washing" ||
              this.searchData.s_type === "addCredit"
            ) {
              this.reportFilename = `Report_washing_machine_${moment(
                this.fromdate
              ).format("YYYY-MM-DD")}_to_${moment(this.todate).format(
                "YYYY-MM-DD"
              )}.xls`;
              data.rows.forEach((value) => {
                value.createdAt = moment(value.createdAt).format(
                  "YYYY-MM-DD HH:mm:ss"
                );
              });
              this.exportField = {
                เบอร์โทรศัพท์: `refNumber`,
                รหัสฟาร์ม: "machineId",
                หมายเลขเครื่อง: "deviceId",
                หมายเลขสล็อต: "slotId",
                เครื่อง: "device.deviceDetails.name",
                สินค้า: "product.name",
                จำนวน: "qty",
                ราคา: "price",
                ยอดหยอด: "acceptorMoney",
                เงินทอน: "changerMoney",
                เครดิตลูกค้าก่อนใช้งาน: "creditCMBefore",
                เครดิตลูกค้าหลังใช้งาน: "creditCMAfter",
                ประเภทการจ่ายเงิน: "paymentType",
                สถานะ: "status",
                วันเวลา: "createdAt",
                วันที่: "date",
                เวลา: "time",
              };
              this.exportData.push({
                refNumber: "",
                machineId: "",
                deviceId: "",
                slotId: "",
                device: {
                  deviceDetails: {
                    name: "",
                  },
                },
                product: {
                  name: "สรุป",
                },
                qty: this.totalRows,
                price: this.totalPrices,
                acceptorMoney: this.totalAcceptorMoney,
                changerMoney: "",
                creditCMBefore: "",
                creditCMAfter: "",
                paymentType: "",
                status: "",
                createdAt: "",
                date: "",
                time: "",
              });
              this.exportFieldOld = {
                เบอร์โทรศัพท์: "refNumber",
                รหัสฟาร์ม: "machineId",
                หมายเลขเครื่อง: "deviceId",
                หมายเลขสล็อต: "slotId",
                สินค้า: "product.name",
                จำนวน: "qty",
                ราคา: "price",
                ยอดหยอด: "acceptorMoney",
                เงินทอน: "changerMoney",
                เครดิตลูกค้าก่อนใช้งาน: "creditCMBefore",
                เครดิตลูกค้าหลังใช้งาน: "creditCMAfter",
                ประเภทการจ่ายเงิน: "paymentType",
                สถานะ: "status",
                วันที่: "createdAt",
                วันที่: "date",
                เวลา: "time",
              };
              this.exportDataOld.push({
                refNumber: "",
                machineId: "",
                deviceId: "",
                slotId: "",
                product: {
                  name: "สรุป",
                },
                qty: this.totalRows,
                price: this.totalPrices,
                acceptorMoney: this.totalAcceptorMoney,
                changerMoney: "",
                creditCMBefore: "",
                creditCMAfter: "",
                paymentType: "",
                status: "",
                createdAt: "",
                date: "",
                time: "",
              });
            } else if (this.searchData.s_type === "vending") {
              this.reportFilename = `Report_vending_machine_${moment(
                this.fromdate
              ).format("YYYY-MM-DD")}_to_${moment(this.todate).format(
                "YYYY-MM-DD"
              )}.xls`;
              data.rows.forEach((value) => {
                value.createdAt = moment(value.createdAt).format(
                  "YYYY-MM-DD HH:mm:ss"
                );
              });
              this.exportField = {
                หมายเลขตู้: "machineId",
                หมายเลขสล็อต: "slotId",
                สินค้า: "product.name",
                sku: "product.sku",
                จำนวน: "qty",
                ราคา: "price",
                ยอดหยอด: "acceptorMoney",
                เงินทอน: "changerMoney",
                ประเภทการจ่ายเงิน: "paymentType",
                สถานะ: "status",
                วันที่: "createdAt",
                วันที่: "date",
                เวลา: "time",
              };
              this.exportData.push({
                machineId: "",
                deviceId: "",
                slotId: "",
                product: {
                  name: "สรุป",
                },
                sku: "",
                qty: this.totalRows,
                price: this.totalPrices,
                acceptorMoney: this.totalAcceptorMoney,
                changerMoney: this.totalChangerMoney,
                paymentType: "",
                status: "",
                createdAt: "",
                date: "",
                time: "",
              });
              //                    this.exportField = {
              //                        หมายเลขตู้: 'machineId',
              //                        สินค้า: 'product.name',
              //                        จำนวน: 'qty',
              //                        ราคา: 'price',
              //                        ยอดหยอด: 'acceptorMoney',
              //                        '1 บาท': 'acceptor.coin.1',
              //                        '2 บาท': 'acceptor.coin.2',
              //                        '5 บาท': 'acceptor.coin.5',
              //                        '10 บาท': 'acceptor.coin.10',
              //                        '20 บาท': 'acceptor.bill.20',
              //                        '50 บาท': 'acceptor.bill.50',
              //                        เงินทอน: 'changerMoney',
              //                        '1x บาท': 'changer.coin.1',
              //                        '2x บาท': 'changer.coin.2',
              //                        '5x บาท': 'changer.coin.5',
              //                        '10x บาท': 'changer.coin.10',
              //                        สถานะ: 'status',
              //                        วันที่: 'createdAt'
              //                    }
            }
          }

          this.$refs.exportModal.show();
        })
        .catch((err) => {
          this.$Progress.fail();
          console.log("error @get Report Export");
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: "เกิดข้อผิดพลาด",
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },
    closeExportModal() {
      // console.log('close')
      this.exportData.pop();
      this.exportDataOld.pop();
      this.$refs.exportModal.hide();
    },
    getData(params) {
      this.searchData.s_type = params;
      this.searchFn();
    },
    getTransactionSum() {
      const params = {
        transactionId: this.searchData.s_id,
        machineId: this.searchData.s_machineid,
        deviceId: this.searchData.s_device,
        status: this.searchData.s_status,
        from: this.fromdate,
        to: this.todate,
        paymentType: this.searchData.s_paymentType,
        refNumber: this.searchData.s_refNumber,
        type: this.searchData.s_type,
      };
      webServices
        .getTransactionSum(params)
        .then((res) => {
          const { totalQty, totalPrices, totalAcceptorMoney } = res.data;
          this.sumQty = totalQty;
          this.sumProfit = totalPrices;
          this.sumAcceptorMoney = totalAcceptorMoney;
        })
        .catch((err) => {
          this.$Progress.fail();
          console.log("error @get Report Export");
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: "เกิดข้อผิดพลาด",
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },
  },
};
</script>

<style>
@import "./style.css";
.spanCustom {
  cursor: pointer;
  color: #20a8d8;
  border-color: #20a8d8;
  text-decoration: underline;
}
.spanCustom:hover {
  text-decoration: none;
  text-shadow: 1px 1px 1px #555;
}
</style>